@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Sofia Pro Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro Regular"),
    url("./assets/font/SofiaProRegular.woff") format("woff");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Sofia Pro Regular",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  min-height: 100vh;
  background-image: url(/public/images/bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fcfaef;
  color: #002159;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin: 0;
}

.animate-visible {
  opacity: 0;
  -webkit-animation: animateVisible ease-out 1s 6s forwards;
  animation: animateVisible ease-out 1s 6s forwards;
}

.animate-visible.with-delay {
  opacity: 0;
  -webkit-animation: animateVisible ease-out 1s 8s forwards;
  animation: animateVisible ease-out 1s 8s forwards;
}

image,
video {
  transition: all 300ms;
}

@-webkit-keyframes animateVisible {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes animateVisible {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-move-in {
  transform: translateX(100%);
  -webkit-animation: animateMoveIn ease-out 1s 7s forwards;
  animation: animateMoveIn ease-out 1s 7s forwards;
}

@-webkit-keyframes animateMoveIn {
  0% {
    transform: translateX(100%);
  }
  70% {
    transform: translateX(10%);
  }
  80% {
    transform: translateX(0);
  }
  90% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes animateMoveIn {
  0% {
    transform: translateX(100%);
  }
  70% {
    transform: translateX(10%);
  }
  80% {
    transform: translateX(0);
  }
  90% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0);
  }
}

.animate-badge-move-down {
  transform: translateY(-100%);
  -webkit-animation: animateMoveDown ease-in 1s 8s forwards;
  animation: animateMoveDown ease-in 1s 8s forwards;
}

@-webkit-keyframes animateMoveDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes animateMoveDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
